import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public moduleNameSubject = new Subject<any>();
  public changeClientSubject = new Subject<any>();
  passValue(data: any) {
    this.moduleNameSubject.next(data);
  }
  passClientName(data: any) {
    this.changeClientSubject.next(data);
  }
}
